export class OrderGroup {
  OrderGroupID: number;
  OrderMethodID: number;
  OrderGroupName: string;
  OrderGroupNumber: string;
  LeadTime: number;
  IsUseStockonDayofDelivery: boolean;

  UserID: any;
  weeklyIndex7: any;
  weeklyIndex6: any;
  weeklyIndex5: any;
  weeklyIndex4: any;
  weeklyIndex3: any;
  weeklyIndex2: any;
  weeklyIndex1: any;
  recurs: any;
  leadTime: any;
  DCID: any;
  OccursID: any;
  OrderGroupTypeID: number;
  departmentType:any;
}

export class ManualOrderAmend{
  storeID:any;
  supplierID:any;
  deliveryDate:any;
  orderNumber:any;
  url:string;
}
