export class ParentSupplier {
  supplierID: number;
  parentSupplierID: number;
  supplierCode: string;
  supplierName: string;
  supplierEmail: string;
  supplierPhone: string;
  supplierMobile: string;
  supplierAddress: string;
  supplierContactName: string;
  purchaseNumber: number;

  isDirect: boolean;
  leadTime: number;
  minQuantity: number;
  maxQuantity: number;

  weeklyIndex1: any;
  weeklyIndex2: any;
  weeklyIndex3: any;
  weeklyIndex4: any;
  weeklyIndex5: any;
  weeklyIndex6: any;
  weeklyIndex7: any;

  haulierName: string;
  haulierAddress: string;
  haulierMobile: string;
  haulierPhone: string;
  haulierEmail: string;
  haulierContactName: string;

  userID: any;
    recurs: string;

    SupplierCalendar: { SupplierID: any; Holiday: any; Reason: any }[];

}

export class ChildSupplier {
  SupplierCalendar: { SupplierID: any; Holiday: any; Reason: any }[];
  supplierID: any;

  // parentSupplierID:number;
  // supplierCode:string;
  // supplierName:string;
  // supplierEmail:string;
  // supplierPhone:string;
  // supplierMobile:string;
  // supplierAddress:string;
  // supplierContactName:string;
  // PurcID:number;

  isDirect: boolean;
  leadTime: any;
  // minQuantity: number;
  // maxQuantity: number;

  weeklyIndex1: any;
  weeklyIndex2: any;
  weeklyIndex3: any;
  weeklyIndex4: any;
  weeklyIndex5: any;
  weeklyIndex6: any;
  weeklyIndex7: any;
  FinalOrderLeadtime:any;
  WeeklyIndex1Time: any;
  WeeklyIndex2Time: any;
  WeeklyIndex3Time: any;
  WeeklyIndex4Time: any;
  WeeklyIndex5Time: any;
  WeeklyIndex6Time: any;
  WeeklyIndex7Time: any;

  // haulierName:string;
  // haulierAddress: string;
  // haulierMobile: string;
  // haulierPhone: string;
  // haulierEmail: string;l
   haulierContactName: string;

  recurs: any;
  startDate: any;
  OrderMethodID: any;
  DCID: any;
  OccursID: any;
  SupplierDeliveryDetailIName: any;
  SupplierDeliveryDetailID: any;
  IsProvisionalGenerate: boolean;
  departmentType:any;
}

export class SupplierDeliveryItems {
  ItemID: number;
  SupplierID: number;
  ItemSupplierIsActive: boolean;
  ItemNumber: string;
  ItemDescription: string;
  SupplierDeliveryDetailID: number;
  DeliveryItemIsActive: boolean;
  IsItemExistsForSupplier: boolean;
  IsItemExistsForDeliveryDetails: boolean;
  SupplierDeliveryDetailIName: string;
}

export class SupplierPurc {
    SupplierPurcID: number;
    SupplierID: number;
    PurcID: number;
    
}

