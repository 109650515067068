export class PurcModel {
        purcID: number;
        purchaseNumber:string;
        parentPurcID: number;
        description: string;
        isActive: boolean;
        userPurc:UserPurcModel;
        purcPromoSchedule: PurcPromoSchedule;
        modifiedBy:number;
        constructor(){
            this.purcPromoSchedule = new PurcPromoSchedule();
            this.userPurc = new UserPurcModel();
        }
}

export class UserPurcModel{
    userID: number;
    purcID: number;
    userPurcID: number;
    isActive: boolean;
    email: string;
    roleId: number;
    userRoleID: number;
    constructor(){
        this.purcID = 0;
        this.userID = 0;
        this.isActive = true;
        this.userPurcID = 0;
        this.email = "";
        this.roleId = 0;
        this.userRoleID = 0;
    }
}


export class PurcPromoSchedule {
    purcPromoScheduleID: number;
    purcID: number;
    dryCPD: number;
    dryCF: number;
    dryDCO: number;
    drySTO: number;
    freshCPD: number;
    freshCF: number;
    freshDCO: number;
    freshSTO: number;

    constructor(){
        this.dryCPD = 0;
        this.dryCF = 0;
        this.dryDCO = 0;
        this.drySTO = 0;
        this.freshCPD = 0;
        this.freshCF = 0;
        this.freshDCO = 0;
        this.freshSTO = 0;
    }
}

export class PurcSummary{
    purcID: number;
    purchaseNumber:string;
    description:string;
    parentPurcID: number;
    parentPurchaseNumber: string;
    isActive: boolean;
}
