import { ForecastType } from './../common/finap-enum';
export class DailyForecastBreakdownSearchModel
{
    itemID: number;
    weekNumber: number;
    transactionDate: Date | string;
    breakdownType: number;
    storeList:string;
    userID:number;
    storeTypeID:number;
    departmentType:number;
    forecastType:ForecastType;
}