import { ItemDetails } from "../item/Item";

export class SalesSearchParams {
  salesHistoryStartDate: any;
  noOfSalesStartWeek: number;
  forecastStartDate: any;
  noOfForecastWeek: number;
  productName: string;
  itemList:string
  clusterList: string;
  outletList: string;
  categoryList: string;
  subCategoryList: string;
  departmentList: string;
  subDepartmentList: string;
  merchandiseDescription: string;
  merchandiseList: string;
  storeTypeID: number;
  departmentType: number;
  supplierList: string;
  quantity: number;
  userID: number;
  salesHistoryEndDate: any;
  isFilterByDate: boolean;
  noOfSalesEndWeek: number;
  planStoreList:any;
  stockPlanner: string;

  bufferStartDate: any;
  bufferEndDate: any;

  salesHistoryStartWeek:any;
  departmentTypeID: number;
  forecastStartWeek: string;
  orderGroupID:any;
  uploadedDate:Date;
  dcList: string;
  effectiveDate: any;
  
  constructor() {
    this.productName = "";
    this.clusterList = "";
    this.outletList = "";
    this.categoryList = "";
    this.subCategoryList = "";
    this.departmentList = "";
    this.subDepartmentList = "";
    this.subCategoryList = "";
    this.merchandiseDescription = "";
    this.supplierList = "";
    this.dcList = "";
    this.stockPlanner = "";
  }
}

export class RawSalesSearchModel {
  salesHistoryStartDate: any;
  forecastStartDate: any;
  noOfForecastWeeks: number;
  noOfSaleWeeks: number;
  productList: string;
  supplierList: string;
  clusterList: string;
  storeList: string;
  storeID: String;
  categoryList: string;
  subCategoryList: string;
  departmentList: string;
  subDepartmentList: string;
  merchandiseList: string;
  storeTypeID: number;
  departmentTypeID: number;
  userID: number;
  IsDepartmentFilter: boolean;
  IsSubDepartmentFilter: boolean;
  IsCategoryFilter: boolean;
  IsSubCategoryFilter: boolean;
  IsMecDescFilter: boolean;
  IsStoreFilter: boolean;
  IsClusterFilter: boolean;
  IsItemFilter: boolean;
  profileweek: string;
  profileYear: string;
  referenceProfileID: number;
  showWTD: boolean;
  itemList: ItemDetails[];
  itmList: string;
  isReferenceWeek: boolean;
  isExist: boolean;
  isBaseWeek: boolean;
  isProfileWeek: boolean;
  SaleWeekList:string;
  ForecastWeekList:string;
  splitLevel:number;
    whProductGroup: string;
    isDI:boolean;
}

export class ForecastItemSearchParams {
  itemID: number;
  salesHistoryStartWeek: number;
  noOfSalesWeek: number;
  forecastStartWeek: number;
  noOfForecastWeek: number;
  outletList: string;
  isFilterByDate: any;
  noOfSalesStartWeek: any;
  noOfSalesEndWeek: any;
  salesHistoryStartDate: any;
  salesHistoryEndDate: any;
  forecastStartDate: string;

  

}

export class SalesBreakdown {
  storeID: string;
  storeName: string;
  sales: number;
}

export class StoreForecstSearchPramas {
  salesHistoryStartWeek: number;
  noOfSalesWeek: number;
  forecastStartDate: string;
  forecastEndDate: string;
  noOfForecastWeek: number;

  itemID: number;
  productName: string;
  clusterList: string;
  outletList: string;
  categoryList: string;
  subCategoryList: string;
  departmentList: string;
  subDepartmentList: string;
  merchandiseDescription: string;
  storeTypeID: number;
  departmentType: number;
  supplierList: string;
  quantity: number;
  forecastAdjType:any;
  adjValue:number;
}

export class SalesPreparationLimit {
  lowerLimit: number;
  upperLimit: number;
  lastUpdatedDate: any;
  storeTypeId: any;
}
