import { ProcessStatus, ProcessAction } from './../common/finap-enum';
import { ScheduleTypes } from "../common/finap-enum";

export class ProcessExecutionDetail
    {
        scheduleType:ScheduleTypes;
        processExecutionID: number;
        startTime: Date | string;
        endTime: Date | string;
        currentProcessDate:Date | string;
        currentStatus:ProcessStatus;
        processStatus:ProcessStatus;
        processAction:ProcessAction;
        currentAction:ProcessAction;
        remarks: string;
        cloudInitiatedTime: Date | string;
        isManual: boolean;
        createdBy: number;  
        lastProcessDate:string;
        currentProcessStatus:string;
        isReRun:boolean;
    }