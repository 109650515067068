export class WeeklyForecastMaintainParameterDetail
    {
        forecastParameterDetailID: number;
        weekNumber: number;
        weeklyWeight: number;
        order: number;
        isActive: boolean;

        constructor(){
            this.forecastParameterDetailID = 0;
            this.isActive = true;
            this.order = 1;
            this.weeklyWeight = 0;
            this.weekNumber = 0;
        }
    }