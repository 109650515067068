import { ForecastAdjustmentMapping } from './../forecast/ForecastAdjustmentMapping';
export class ProductHeitrachySearchModel{
    itemList:number[];
    supplierList:number[];
    storeList:number[];
    clusterList:number[];
    departmentList:number[];
    subDepartmentList:number[];
    categoryList:number[];
    subCategoryList:number[];
    merchandiseList:number[];
    adjList:ForecastAdjustmentMapping[];
}