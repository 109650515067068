import { WeeklyForecastMaintainParameterDetail } from './WeeklyForecastMaintainParameterDetail';
export class WeeklyForecastMaintainParameter
    {
        forecastParameterID: number;
        itemID: number;
        hierarchyLevelID: number;
        noOfHistoryWeeks: number;
        noOfForecastWeeks: number;
        isCurrent: boolean;
        forecastParameterDetails: WeeklyForecastMaintainParameterDetail[];
        isIgnoreHierarchy:boolean;
        IsDefaultChanged:boolean;
        DefaultChangedTo:number;
        StoreTypeId: number;
        UserId: number;


        constructor(){
            this.noOfForecastWeeks = 0;
            this.noOfHistoryWeeks = 0;
            this.isCurrent = true;
            this.forecastParameterID = 0;
            this.itemID = 0;
            this.hierarchyLevelID = 0;
            this.forecastParameterDetails = [];
        }

}