export class UserRoleModel{
    userID: number;
    purcID: number;
    userPurcID: number;
    roleID:number;
    roleName:string;
    isActive:boolean;
    isMobileTeller:boolean; 
    createdUser:number;
    
}